import * as React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import { useGraphQL } from '../hooks/use-graphql';
import {
  Layout,
  SEO,
  Map,
  Contact,
  OurClients,
  SuitsOverlay,
} from '../components';
import { Logo } from '../components/vectors';
import { useServices } from '../data/services';

function IndexPage() {
  return (
    <Layout>
      <SEO title="Bases for Poker and Electronic Gaming Machines" />
      <Hero />
      <SuitsOverlay />
      <AboutSection />
      <Products />
      <SuitsOverlay />
      <Services />
      <OurClients />
      <Contact />
      <Map />
    </Layout>
  );
}

function Hero() {
  const {
    file,
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "landing-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <header style={{ minHeight: '70vh' }} className="relative flex">
      <div className="absolute inset-0 flex">
        <GatsbyImage fluid={file.childImageSharp.fluid} className="flex-1" />
        <div
          aria-hidden
          className="absolute inset-0 pointer-events-none hero-gradient"
        >
          <div className="absolute inset-0 bg-black bg-opacity-50 " />
        </div>
      </div>
      <div className="relative flex items-center w-full pt-40 pb-48 mx-auto max-w-7xl text-shadow lg:py-48">
        <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
          <h1>
            <span className="sr-only">{siteMetadata.title}</span>
            <Logo
              aria-hidden
              focusable={false}
              className="w-full max-w-sm fill-current text-gold"
            />
          </h1>
          <p className="max-w-md mt-3 text-lg text-white sm:text-xl md:mt-5 md:max-w-3xl">
            {siteMetadata.title} design and manufacture premium electronic
            gaming machine bases for gaming venues around Australia. Select from
            bases, screens, accessories and design your own gaming room to fit
            your specifications.
          </p>
          <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
            <div className="w-full max-w-xs rounded-full">
              <a href="#contact-form" className="button">
                Get started
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

function AboutSection() {
  const {
    site: { siteMetadata },
  } = useGraphQL();
  return (
    <article className="relative text-center bg-navy">
      <div className="w-full px-4 py-20 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:py-48">
        <h2 className="font-mono text-4xl text-white">About Us</h2>
        <div className="mx-auto mt-6 prose text-white">
          <p>
            {siteMetadata.title} provide quality Electronic Gaming Machine (EGM)
            bases, infill benches, and screens to a variety of gaming venues
            around Australia.
          </p>
          <p>
            We have had over 20 years in the Gaming industry ensuring we are in
            tune with the requirements of comfort for the venues customers so
            they can enjoy their Gaming Experience.
          </p>
          <p>
            We design our bases so that staff and technicians have easy access
            under the bench for cabling or collection of coins.
          </p>
          <p>
            We use the highest quality materials and innovative processes to
            ensure your EGM bases enhance your gaming room décor and design. We
            design all our EGM bases in-house, taking the lead in project
            management to ensure timely delivery.
          </p>
          <p>
            {siteMetadata.title} is the trusted name for manufacture of EGM
            bases, custom gaming benches and screens, demonstrated by our
            consistency in quality.
          </p>
        </div>
      </div>
    </article>
  );
}

function Product({ children, heading, image, reverse, slug }) {
  function Image() {
    return (
      <div className={!reverse && 'order-first lg:order-none'}>
        <div className="max-w-lg mx-auto">
          <GatsbyImage fluid={image.childImageSharp.fluid} />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="grid gap-8 lg:grid-cols-2">
        {reverse && <Image />}
        <div className="w-full my-auto">
          <h2 className="heading-3 text-navy">{heading}</h2>
          <div className="mt-4 prose">{children}</div>
          {slug && (
            <p className="mt-6">
              <Link
                aria-label={`Learn more about ${heading}`}
                to={slug}
                className="button"
              >
                Learn more
              </Link>
            </p>
          )}
        </div>
        {!reverse && <Image />}
      </div>
    </div>
  );
}

Product.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  reverse: PropTypes.bool,
  slug: PropTypes.string.isRequired,
};

function Products() {
  const { landingImage1, landingImage2 } = useGraphQL();
  return (
    <article className="relative bg-white">
      <div className="w-full px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:pb-36 lg:pt-24">
        <h2 className="text-center heading-2 text-navy">Our Products</h2>
        <h3 className="text-2xl text-center text-gold">
          Tailored to suit your venue
        </h3>
        <div className="lg:mt-12">
          <Product
            heading="Customised Gaming Benches"
            image={landingImage1}
            // slug="/products/custom-gaming-base/"
          >
            <p>
              Order EGM bases in custom colours and finishes that suit your
              venue. Choose from:
            </p>
            <ul>
              <li>Custom base colours</li>
              <li>Custom benchtop colours</li>
              <li>Custom leading edge profiles</li>
              <li>Custom add-ons or finishes</li>
            </ul>
          </Product>
          <Product
            heading="Customised Gaming Screens"
            image={landingImage2}
            reverse
            // slug="/products/custom-gaming-screen/"
          >
            <p>
              Colours, materials, and sizes made and fit to order. Create a
              unique solution that blends with your venue décor and layout.
            </p>
            <ul>
              <li>Custom base colours</li>
              <li>Custom benchtop colours</li>
              <li>Custom leading-edge profiles</li>
              <li>Custom cut-outs or patterned screen designs</li>
            </ul>
          </Product>
        </div>
      </div>
    </article>
  );
}

function Services() {
  const services = useServices();
  return (
    <article className="bg-navy py-36">
      <div className="w-full px-4 mx-auto space-y-12 max-w-7xl sm:px-6 lg:px-8">
        <h2 className="mt-24 text-center text-white heading-2">
          Complete Gaming Base Services
        </h2>
        <div className="grid max-w-2xl gap-8 mx-auto space-y-12 text-center lg:grid-cols-3 lg:max-w-none lg:space-y-0">
          {services.map((service) => (
            <div key={nanoid()} className="flex flex-col">
              <service.icon className="w-24 h-24 mx-auto text-gold" />
              <h3 className="mt-4 text-2xl font-light text-center text-gold">
                {service.title}
              </h3>
              <div className="mx-auto mt-2 prose text-center text-white">
                <p>{service.preview}</p>
              </div>
              <p className="pt-6 mt-auto">
                <Link to={service.url} className="button">
                  Learn more
                </Link>
              </p>
            </div>
          ))}
        </div>
      </div>
    </article>
  );
}

export default IndexPage;
